























































import Vue, { PropType } from "vue";

import SocialSharing from "vue-social-sharing";

Vue.use(SocialSharing);

import BusinessObject from "../interfaces/business";

export default Vue.extend({
	data() {
		return {
			clicked: false as boolean,
			share: {
				title:
					"I just order some food from " +
					this.business.name +
					"!\n\nJoin me in supporting our local restaurants during this difficult time at SociallyDistantEats.com",
				url: "https://sociallydistanteats.com"
			}
		};
	},

	props: {
		business: Object as PropType<BusinessObject>,
		layout: String
	},

	methods: {
		newLineIt(text: string): string {
			return text.replace(/\n/g, "<br/>");
		},

		click(url: string, newWindow: boolean = false) {
			this.clicked = true;

			if (newWindow) {
				window.open(url);
			} else {
				window.location.href = url;
			}
		}
	},

	computed: {
		distance(): null | string {
			return this.business.distance
				? parseFloat(this.business.distance).toFixed(2)
				: null;
		},

		hasServices(): boolean {
			return (
				this.hasTakeout ||
				this.hasOnlineOrdering ||
				this.hasDelivery ||
				this.hasCurbsidePickup
			);
		},

		hasTakeout(): boolean {
			return true;
		},

		hasOnlineOrdering(): boolean {
			return this.business.online_ordering === "TRUE" ? true : false;
		},

		hasDelivery(): boolean {
			return this.business.delivery === "TRUE" ? true : false;
		},

		hasCurbsidePickup(): boolean {
			return this.business.curbside_pickup === "TRUE" ? true : false;
		},

		singleLineAddress(): string {
			let address = [];

			if (this.business.street_address) {
				address.push(this.business.street_address);
				address.push(", ");
			}

			if (this.business.city) {
				address.push(this.business.city);

				if (this.business.state || this.business.zip_code) {
					address.push(", ");
				}
			}

			if (this.business.state) {
				address.push(this.business.state);

				if (this.business.zip_code) {
					address.push(" ");
				}
			}

			if (this.business.zip_code) {
				address.push(this.business.zip_code);
			}

			return address.join("");
		},

		directionsLink(): string {
			return "https://www.google.com/maps/dir//" + this.singleLineAddress;
		}
	},

	filters: {}
});
