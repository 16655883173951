















import Vue, { PropType } from "vue";

import "../node_modules/font-awesome/scss/font-awesome.scss";
import "./assets/scss/style.scss";

export default Vue.extend({
	data() {
		return {
			attribution: "SociallyDistantEats.com is the project conceived by Nick Goers of <a href='https://www.company119.com' target='_blank'>Company 119</a> to help local restaurants during the COVID-19 crisis. Please consider suggesting more local restaurants for the list!" as string
		};
	}
});
