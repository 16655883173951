import Vue from "vue";
import Vuex, { Store } from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {},

	mutations: {},

	actions: {},

	getters: {},
	modules: {}
});
