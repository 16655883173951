





































































































import Vue, { PropType } from "vue";

import BusinessObject from "@/interfaces/business";
import BusinessComponent from "@/components/Business.vue";
import MapComponent from "@/components/Map.vue";

import _ from "lodash";
import tabletop from "tabletop";
import axios from "axios";
import VueMasonry from "vue-masonry-css";

Vue.use(VueMasonry);

export default Vue.extend({
	data() {
		return {
			viewMode: "map" as string,
			isLoading: true as boolean,
			restaurants: [] as Array<BusinessObject>,
			sortBy: "name" as string,
			filters: {
				search: {
					input: "" as string,
				},
				service: {
					input: "" as string,
					options: {
						curbside_pickup: "Curbside Pickup",
						delivery: "Delivery",
						online_ordering: "Online Ordering",
					},
				},
				city: {
					input: "" as string,
					options: [] as Array<string>,
				},
			},
			selectedFilters: [] as Array<string>,
		};
	},

	created() {
		axios
			.get(
				"https://api.sheety.co/4a947fe924bd34d44b5c0563c89731e9/sociallyDistantEatsCom/restaurants"
			)
			.then((resp) => {
				if (!!resp.data) {
					this.loadData(resp.data);
				}
			});
	},

	mounted() {
		if (this.$route.params.city_name) {
			this.filters.city.input = this.$route.params.city_name;
		}
	},

	methods: {
		loadData(data: any) {
			const restaurants = data.restaurants;
			this.restaurants = restaurants;
			this.isLoading = false;
		},

		reset() {
			this.$router.push("/");
			this.filters.city.input = "";
			this.filters.service.input = "";
			this.filters.search.input = "";
		},

		goToCityPage() {
			this.$router.push("/city/" + this.filters.city.input);
		},

		setGeolocation(userLatLng: google.maps.LatLng) {
			this.restaurants.forEach((restaurant: BusinessObject, index) => {
				let distance = this.calculateDistance(
					userLatLng.lat(),
					userLatLng.lng(),
					parseFloat(restaurant.latitude),
					parseFloat(restaurant.longitude)
				);

				this.restaurants[index].distance = distance.toString();
			});

			this.sortBy = "distance";
		},

		calculateDistance(
			lat1: number,
			lon1: number,
			lat2: number,
			lon2: number,
			unit: string = "M"
		): number {
			var radlat1 = (Math.PI * lat1) / 180;
			var radlat2 = (Math.PI * lat2) / 180;
			var radlon1 = (Math.PI * lon1) / 180;
			var radlon2 = (Math.PI * lon2) / 180;
			var theta = lon1 - lon2;
			var radtheta = (Math.PI * theta) / 180;
			var dist =
				Math.sin(radlat1) * Math.sin(radlat2) +
				Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
			dist = Math.acos(dist);
			dist = (dist * 180) / Math.PI;
			dist = dist * 60 * 1.1515;

			if (unit == "K") {
				dist = dist * 1.609344;
			}

			if (unit == "N") {
				dist = dist * 0.8684;
			}

			return dist;
		},
	},

	computed: {
		showMap(): boolean {
			return this.viewMode === "map";
		},

		isFiltered(): boolean {
			return (
				!!this.filters.search.input ||
				!!this.filters.city.input ||
				!!this.filters.service.input
			);
		},

		activeList(): Array<BusinessObject> {
			let list = this.restaurants.filter(
				(restaurant: BusinessObject) => restaurant.name !== ""
			);

			return list;
		},

		sortedList(): Array<BusinessObject> {
			let list = this.filteredList;

			if (this.sortBy) {
				if (this.sortBy === "name") {
					list = _.sortBy(list, ["name"]);
				} else if (this.sortBy === "distance") {
					list = list.sort(function (a, b) {
						return parseFloat(a.distance) - parseFloat(b.distance);
					});
				}
			}

			return list;
		},

		filteredList(): Array<BusinessObject> {
			let list = this.activeList;

			if (this.filters.search.input) {
				list = list.filter((item: BusinessObject) => {
					return item.name
						.toLowerCase()
						.includes(this.filters.search.input.toLowerCase());
				});
			}

			if (this.filters.city.input) {
				list = list.filter(
					(item: BusinessObject) =>
						item.city.toLowerCase() ===
						this.filters.city.input.toLowerCase()
				);
			}

			if (this.filters.service.input) {
				list = list.filter(
					(item: BusinessObject) =>
						item[this.filters.service.input] === "TRUE"
				);
			}

			return list;
		},

		uniqueCities(): Array<string> {
			if (this.restaurants) {
				const restaurants: Array<BusinessObject> = this.restaurants;
				const uniqueCities: Array<string> = [
					...new Set(restaurants.map((item: BusinessObject) => item.city)),
				];
				return uniqueCities.sort();
			}

			return [];
		},
	},

	watch: {
		uniqueCities(values) {
			this.filters.city.options = values;
		},

		$route(to, from) {
			this.filters.city.input = to.params.city_name
				? to.params.city_name
				: "";
		},
	},

	filters: {},

	components: {
		business: BusinessComponent,
		mapbox: MapComponent,
	},
});
